.Testimonial{
    display: flex;
    gap: 1rem;
    padding: 0 2rem;
}
.left-t{
    display: flex;
    flex: 1 1 ;
    gap: 2rem;
    flex-direction: column;
    text-transform: uppercase;
    color: white;
}
.left-t>:nth-child(1){
    color: var(--orange);
    font-weight: bold;
}
.left-t>:nth-child(2)
, .left-t>:nth-child(3){
    font-weight: bold;
    font-size: 3rem;
}
 .left-t>:nth-child(4){
    transform: none;
    text-align: justify;
    letter-spacing: 2px;
    line-height: 40px;
 }
 .right-t{
     flex: 1;
     position: relative;
    }
    .right-t>:nth-child(1){
        position: absolute;
        width: 17rem;
        height: 20rem;
        border: 2px solid var(--orange);
        right: 10rem;
        top: 0.9rem;


    }
    .right-t>:nth-child(2){
    position: absolute;
    background: var(--planCard);
    width: 17rem;
    height: 20rem;
    top: 3rem;
    right: 7rem;
    }
    .right-t>img{
        position: absolute;
        width: 17rem;
        height: 20rem;
        object-fit: cover;
        right: 8rem;
        top: 2rem;
    }
    .arrows{
        display: flex;
        gap: 1rem;
        position: absolute;
        bottom: 1rem;
        left: 23rem;
    }
    .arrows>img{
        width: 1.5rem;
        cursor: pointer;
    }
    @media screen and (max-width:768px) {
        .Testimonial{
            flex-direction: column;
        }
        .left-t>:nth-child(2),
        .left-t>:nth-child(3){
            font-size: xx-large;
        }
        .right-t{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 2rem;
        }
        .right-t>div{
            position: relative;
            display: none;
        }
        .right-t>img{
            top: 0;
            right: 0;
            position: relative;
            align-self: center;
        }
        .right-t>:last-child{
            display: block;
            bottom: 0;
            left: 0;
            /* gap: 2rem; */
        }
        .right-t>:last-child>img{
            /* gap: 2rem; */
            margin: 0 10px;
        }
    }