*{
    text-decoration: none;
    list-style: none;
}
.header{
    display: flex;
    justify-content: space-between;
}
.logo{
    width: 10rem;
    height: 3rem;
}
.header-menu{
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    color: #fff;
    transition: all 0.3s ease;
    cursor: pointer;
}
.header-menu li:hover{
    /* padding: 0 10px; */
    color: var(--orange);

}
@media screen and (max-width:768px) {
    .header>:nth-child(2){
        position: fixed ;
        right: 2rem;
        z-index: 99;
    }
    .header-menu{
        flex-direction: column;
        background: var(--appColor);
        padding: 2rem;
    }
}
