.plans-containers{
    margin-top: -4rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
}
.plans-blur-1{
width: 35rem;
 height: 32rem; 
 top: 6rem;
 left: 0;
}
.plans-blur-2{
    width: 35rem;
    height: 34rem;
    top: 10rem;
    right: 0;
}
.plans{
    display: flex;
    align-items: center;
    justify-content: center;
    /* flex-direction: row; */
    /* margin: auto; */
    gap: 7rem;
}
.plan{
    display: flex;
    flex-direction: column;
    color: white;
    gap: 2rem;
    padding: 1.5rem;
    width: 15rem;
    background: var(--caloryCard);
}
.plan:nth-child(2){
    background: var(--planCard);
    transform: scale(1.1);
    
}
.plan>:nth-child(2){
    font-size: 1.5rem;
    font-weight: bold;
}
.plan>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
}
.plan>:nth-child(5){
    font-size: 0.9rem;

}
.plan>svg{
    fill: var(--orange);
    width: 2rem;
    height: 2rem;
}
.plan:nth-child(2)>svg{
    fill: #fff;
}
.plan:nth-child(2)>button{
    color: var(--orange);
}
.features{
/* .features{ */
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.feature{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.feature>img{
    width: 1.5rem ;
}
@media screen and (max-width:768px) {
    .plans{
        flex-direction: column;
        
    }
    .plan:nth-child(2){
        transform: none;
    }
}